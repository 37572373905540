import  cardImgone  from '../Images/Card-img-4.webp';
import  cardImgtwo  from '../Images/Card-img-5.webp';
import  cardImgthree  from '../Images/Card-img-7.webp';
import  cardImgfour  from '../Images/Card-img-8.webp';
import  cardImgfive  from '../Images/Card-img-9.webp';
import  cardImgsix  from '../Images/Card-img-10.webp';
import  cardImgseven  from '../Images/Card-img-11.webp';
import  cardImgeight  from '../Images/Card-img-12.webp';
import  cardImgnine  from '../Images/Card-img-13.webp';
import  cardImgten  from '../Images/Card-img-14.webp';
import  cardImgeleven  from '../Images/Card-img-18.webp';

import Bannerimage from '../Images/Futurre copy.webp';
// import EvouchrLogo from '../Images/Evouchr-logo-webapp.png';
import EvouchrLogo from '../Images/Evouchr-logo-webapp.svg';
import playStoreImg from '../Images/playstore.svg';

// Old Images card collage section

// import CardCollageImg1 from '../Images/Top-left-copy.webp';
// import CardCollagaImg2 from '../Images/New-Card-Collage-Image-second.webp';
// import CardCollageImg3 from '../Images/New-Card-Collage-image-3.webp';
// import CardCollageImg4 from '../Images/New-Card-Collage-image-4.webp';
// import CardCollageImg5 from '../Images/New-Card-Collage-Image-5.webp';
// import CardCollageImg6 from '../Images/New-Card-Collage-Image-6.webp';
// ends

// New Images card collage section

// import CardCollageImg1 from '../Images/one-card-collage-image.webp';
// import CardCollagaImg2 from '../Images/two-card-collage-image.webp';
// import CardCollageImg3 from '../Images/three-card-collage-image.webp';
// import CardCollageImg4 from '../Images/four-card-collage-image.webp';
import CardCollageImg5 from '../Images/five-card-collage-image0.webp';
import CardCollageImg6 from '../Images/New-Card-Collage-Image-05.webp'; 

// import StackedCardImageOne from '../Images/Stacked-Card-Image-1.webp';
import StackedCardImageTwo from '../Images/Stacked-Card-Image-2.webp';
// import StackedCardImageThree from '../Images/Stacked-Card-Image-3.webp';
// import StackedCardImageFour from '../Images/Stacked-Card-Image-4.webp';
// import StackedCardImageFive from '../Images/Stacked-Card-Image-5.webp';

//Phone Skleton with SS

import EmptyPhoneSkleImage from '../Images/Empty-Phone-background-Image.webp';
import ProductDetlsSS from '../Images/Crd-Detls-SS-BT.webp';



import prepaid from "./PrepaidCard.webp"
import esim from "./esimsCard.webp"
import gift from "./giftCard.webp"



import btnArrow from "./orange arrow.svg"



export const BannerFrameOne = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/gift%20card%20bento%20banner%20web.webp';
export const BannerFrameTwo = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/esims%20bento%20banner%20web.webp';
export const BannerFrameThree = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/refill%20bento%20banner%20web.webp';


export const BannerFrameOneMob = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/gift%20cards%20bento%20banner%20mob.webp';
export const BannerFrameTwoMob = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/esims%20bento%20banner%20mob.webp';
export const BannerFrameThreeMob = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/refill%20bento%20banner%20mob.webp';


export const ButtonArrow = btnArrow;

export const prepaidCard = prepaid;
export const giftCard = gift;
export const esimCard = esim;


//exportSkleton with SS
export const EmptyPhoneBackground = EmptyPhoneSkleImage;
export const ProductDetils = ProductDetlsSS;
export const playstore = playStoreImg;

// Stacked Card Image Exported 

// export const StackedImageOne = StackedCardImageOne;
export const StackedImageTwo = StackedCardImageTwo;
// export const StackedImageThree = StackedCardImageThree;
// export const StackedImageFour = StackedCardImageFour;
// export const StackedImageFive = StackedCardImageFive;


export const StackedImageOne = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/Stacked-Card-2.webp';
// export const StackedImageTwo = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/Stacked-Card-3.webp';
// export const StackedImageThree = StackedCardImageThree;
export const StackedImageFour = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/Stacked-Card.webp';
export const StackedImageFive = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/Stacked-Card-1.webp';

// End Exported 

// export const CollageImageLeftOne = CardCollageImg1;
// export const CollageRightUpTwo = CardCollagaImg2;
// export const CollageMiddleImgThree = CardCollageImg3;
// export const CollageMiddleImgFour = CardCollageImg4;
export const CollageMiddleImgFive = CardCollageImg5;
export const CollageMiddleImgSix = CardCollageImg6;


export const CollageImageLeftOne = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/one-card-collage.webp';
export const CollageRightUpTwo = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/two-card-collage.webp';
export const CollageMiddleImgThree = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/three-card-collage.webp';
export const CollageMiddleImgFour = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/four-card-collage.webp';
// export const CollageMiddleImgFive = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/five-card-collage.webp';
// export const CollageMiddleImgSix = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/mainpage/New-Card-Collage.webp';

export const MainBannerImage = Bannerimage;

export const MainWebLogoBit = EvouchrLogo;

export const Imageone = cardImgone;
export const Imagetwo = cardImgtwo;
export const Imagethree = cardImgthree;
export const Imagefour = cardImgfour;
export const Imagefive = cardImgfive;
export const Imagesix = cardImgsix;
export const Imageseven = cardImgseven;
export const Imageeight = cardImgeight;
export const Imagenine = cardImgnine;
export const Imageten = cardImgten;
export const Imageeleven = cardImgeleven;