import React from 'react'
import { BannerFrameOne, BannerFrameThree, BannerFrameTwo, ButtonArrow, esimCard, giftCard, prepaidCard } from '../../../Images';
import "./CardComponent.css"
import { BsArrowUpRight } from "react-icons/bs";

function CardComponent() {
  return (
    <div className='cardComponentContainer'>
            <div className="cardSection">


        <div className="cardsMainContainer">
        <div className="textSection">
                
                <h3 className='cardHeading'>Gift Vouchers</h3>
                <p className='cardPara'>Explore All Exclusive gift vouchers, Perfect for everyone!</p>
                </div>
                <div className="cardImageSection">

<img src={giftCard} alt=""  className='CardImages'/>
    <a href="https://evouchr.com/vouchers">
<button className='cardButton'>
<BsArrowUpRight className="buttonArrow"/>

</button>
    </a>
    </div>
        </div>

        
        <div className="cardsMainContainer">
        <div className="textSection">
                
                <h3 className='cardHeading'>eSims</h3>
                <p className='cardPara'>Explore All eSIMS Instant  activation for global connectivity.</p>
                </div>
                <div className="cardImageSection">

            <img src={esimCard} alt=""  className='CardImages'/>

            <a href="https://evouchr.com/esims">
            <button className='cardButton'>
            <BsArrowUpRight className="buttonArrow"/>
            </button>
            </a>
                
                </div>
        </div>

        <div className="cardsMainContainer">

<div className="textSection">
    
<h3 className='cardHeading'>Prepaid Cards</h3>
<p className='cardPara'>Explore All Prepaid Cards Fast, easy mobile topup anytime, anywhere!</p>
</div>

<div className="cardImageSection">

<img src={prepaidCard} alt=""  className='CardImages'/>
<a href="https://evouchr.com/vouchers/cash-&-prepaid-card
">
<button className='cardButton'>
<BsArrowUpRight className="buttonArrow"/>

</button>
</a>
</div>
</div>
    </div>
      
    </div>
  )
}

export default CardComponent
