import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { BannerFrameOneMob, BannerFrameThreeMob, BannerFrameTwoMob } from "../../../Images";
import "./BannerComponent.css";
function BannerMobComponent() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="sliderMain">
<img className="sliderImages" src={BannerFrameOneMob} alt="" />   
          <a href="https://evouchr.com/vouchers">
<button className="bannerButtonSlider" id="voucherButton">Explore Now</button>
          </a>
     </div>
     <div className="sliderMain">
     <img className="sliderImages" src={BannerFrameTwoMob} alt="" />     
          <a href="https://evouchr.com/esims">
        <button className="bannerButtonSlider" id="esimButton">Check Esims</button>
 
          </a>
        </div>
        <div className="sliderMain">
        <img className="sliderImages" src={BannerFrameThreeMob} alt="" />    
          <a href="https://evouchr.com/phonerefills">
        <button className="bannerButtonSlider refills" id="refillsButton">Check Refills</button>
  
          </a>
        </div>
     

      </Slider>
    </div>
  );
}

export default BannerMobComponent;
