import React from "react";
import './ContactUsComponent.css';
import { TextField } from "@mui/material";
import '../ContactUsComponent/ContactUsComponent.css';


const ContactUsForm = () =>{
    return(
        <>
            <div>
            <form className="FormTetFiledDesign">
                            <TextField
                                className="ContactUsTxtBox"
                                fullWidth
                                label="Enter Your Name"
                                variant="outlined"
                                type="text"
                                name="text"
                                // value={values.email}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                margin="normal"
                                placeholder="Example: John Cena"                                
                                required
                            />

                            <TextField
                                className="ContactUsTxtBox"
                                fullWidth
                                label="Enter Your Email"
                                variant="outlined"
                                type="email"
                                name="email"
                                // value={values.email}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                margin="normal"
                                placeholder="Example: Jhon@gmail.com"
                                required
                            />

                            <TextField
                                className="ContactUsTxtBox"
                                fullWidth
                                label="Enter Your Phone"
                                variant="outlined"
                                type="number"
                                name="number"
                                // value={values.email}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                margin="normal"
                                placeholder="Example: 999-999-9999"
                                required
                            />

                            <TextField
                                className="ContactUsTxtBox"
                                fullWidth
                                label="Enter Your Description"
                                variant="outlined"
                                type="text"
                                name="text"
                                // value={values.email}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                margin="normal"
                                placeholder="Example: Write something..."
                                required
                                rows={4}
                                multiline
                            />

                            <button class="RedirectionButtonLandingPage" type="submit"><a href="#">  Get Evouchr </a></button>
                        </form>
            </div>
        </>
    )
}

export default ContactUsForm