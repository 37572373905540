import React from 'react';
import { BannerFrameOne, BannerFrameThree, BannerFrameTwo, esimCard, giftCard, refillCard } from '../../../Images';
import './BannerComponent.css';

function BannerWebComponent() {
  return (

    <div className="bannerSection">
    <div className="bannerMainContainer">
      <div className="bannerLeftContainer">
        <img src={BannerFrameOne} alt="Banner Left" />
        <a href="https://evouchr.com/vouchers">
        <div className="bannerButtonOne" id="voucherButton">Explore Now</div>

        </a>
      </div>
      <div className="bannerRightContainer">
        <div className="bannerRightTopContainer">
          <img src={BannerFrameTwo} alt="Banner Right Top" />
          <a href="https://evouchr.com/esims">
          <div className="bannerButtonTwo" id="esimButton">Check Esims</div>

          </a>
        </div>
        <div className="bannerRightBottomContainer">
          <img src={BannerFrameThree} alt="Banner Right Bottom" />
          <a href="https://evouchr.com/phonerefills">
          <div className="bannerButtonTwo refills" id="refillsButton">Check Refills</div>
          </a>
        </div>
      </div>
    </div>



    </div>

  );
}

export default BannerWebComponent;
