import React from 'react'
import { playstore } from '../../../Images'
import "./playstoreDownload.css"


function PlaystoreDownload() {
  return (
    <div className='PlaystoreContainer'>
        <a href="https://play.google.com/store/apps/details?id=com.evouchr.evouchr&hl=en">
            <img src={playstore} alt="" className='playstoreImg'/>
        </a>
      
    </div>
  )
}

export default PlaystoreDownload
