import React from "react";
import './NewCardCollageSection.css';
import NewCardCollageWeb from "./NewCardCollageWeb";
import NewCardCollageMob from "./NewCardCollageMob";


const NewCardCollageSection = () => {

  const handleCardClick = (categoryName) => {
    console.log('categoryName: ', categoryName);
    const baseUrl = 'https://evouchr.com/vouchers';
  
    if (categoryName === "esims") {
      // Open the esims page in a new tab
      window.open('https://evouchr.com/esims', '_blank');
      
    } else if (categoryName) {
      // Open the corresponding category page in a new tab
      window.open(`${baseUrl}/${categoryName}`, '_blank');
      
    } else {
      // Open the default vouchers page in a new tab
      window.open(`${baseUrl}`, '_blank');
    }
  
    // Clear the selectedFilters key from localStorage after opening the link
    localStorage.removeItem('selectedFilters');


  };
  
  
  
    return(
            <>
                <div className="MainCardCollageSection">
                        <h2 className="MainHeadingTxtSect" > Explore Voucher with Crypto</h2>
                        <NewCardCollageWeb handleCardClick={handleCardClick} id="NewCardCollageWeb"/>
                        <NewCardCollageMob handleCardClick={handleCardClick} id="NewCardCollageMob"/>
     
                </div>
            </>
    )
}

export default NewCardCollageSection