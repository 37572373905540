import React from 'react'
import './NewCardMob.css';
import { CollageImageLeftOne, CollageMiddleImgFive, CollageMiddleImgFour, CollageMiddleImgSix, CollageMiddleImgThree, CollageRightUpTwo } from "../../../Images";


function NewCardCollageWeb({handleCardClick}) {
  return (
    <div className='newCardWeb'>
                     <div className="SecondaryContentCollege">
                        <div className="FirstCardCollage" >
                            <img src={CollageRightUpTwo} className="CollageImageOne"/>
                            <div className="FirstCardCollegeDesc">
                            <h3 className="FirstCardCollHeadTxt"> Travel & Entertainment</h3> 

                                <p>
                            Create unforgettable memories effortlessly, thanks to the convenience of cryptocurrency transactions. Discover the best crypto entertainment gift cards and let your journeys begin!
                                </p>

                                <button className="button" id='travelEntertainmentBtn' onClick={() => handleCardClick('Travel & Entertainment')}>Order Now</button>
                                </div>
                        </div>

                        <div className="SecondMainCardCollageSect">
                            <div>
                                <div className="SecondCardCollage" >
                                    <div className="SecondCollageHeadTxt">
                                        {/* <h3>Game On With Crypto</h3> */}
                                        <h3>Stay Connected On-The-Go </h3>
                                        <p>eSIMs offer seamless activation and continuous connectivity without the need to swap out your physical SIM card. Buy your eSIMs with cryptocurrency for added convenience and flexibility. Experience the ease of staying connected with a crypto eSIM, all while using your preferred digital currency.</p>
                                        {/* <p>Get access to popular gaming platforms, in-game purchases, and subscription services using crypto.</p> */}
                                        <button className="button" id='esimsBtn' onClick={() => handleCardClick('esims')}>Explore Now</button>
                                    </div>

                                    <div className="SecondCollageImg">
                                        <img src={CollageMiddleImgFive} className="CollageImageTwo" />
                                        {/* <img src={CollageImageLeftOne} className="CollageImageTwo" /> */}
                                    </div>
                                </div>

                                <div className="ThirdSetionCards">
                                    <div className="ThirdCardCollageSect" >
                                        <div className="ThirdSectinMainImg">
                                            <img src={CollageMiddleImgFour} className="CollageImageThree"/>
                                        </div>
                                        <div className="ThirdSecHeadTxt">
                                            <h3> Virtual Cards</h3>
                                            <p>
                                            Experience the ease of virtual cards for all your online transactions. Purchase virtual cards with Bitcoin, Ethereum, USDT, Solana, Tether, and more, making every online transaction smoother and hassle-free.
                                            </p>

                                            <button className="button" id='virtualCardsBtn' onClick={() => handleCardClick('cash-&-prepaid-card')}>Shop Now</button>
                                        </div>
                                    </div>

                                    <div className="FourthSectionCards">
                                        <div className="FourthCardCollageSect">
                                            <div className="FourthSecHeadTxt"> 
                                                {/* <h3>Stay Connected On-The-Go </h3> */}
                                                <h3>Game On With Crypto </h3>
                                                <p>Gain access to popular gaming platforms such as Steam, PlayStation Network, and Xbox Live using crypto. Effortlessly use cryptocurrency for in-game purchases and subscriptions, enhancing your gaming experience with seamless and secure transactions. </p>
                                                {/* <p>Evouchr eSIMs are seamlessly activated and stay connected without the hassle of changing your physical SIM card, all purchasable with cryptocurrencies.</p> */}
                                                <button className="button" id='gamingBtn'  onClick={() => handleCardClick('Gaming')}>Buy Now</button>
                                            </div>

                                            <div className="ThirdSectinMainImg">
                                                <img src={CollageImageLeftOne} className="CollageImageFour"/>
                                                {/* <img src={CollageMiddleImgFive} className="CollageImageFour"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="CardCollageMainRemContent">
                        <div className="CardCollageCardFive" >
                            <div className="lifestyleSection">
                                <div className="FithSectMainImg">
                                    <img src={CollageMiddleImgSix} className="CollageImageFifth lifestyle"/>
                                </div>
                                <div className="FithSectMainHeadTxt">
                                    <h3 >Fashion & Lifestyle</h3>
                                    <p className="FithSectMainSecTxt">
                                    Fashion redefined! A new way to shop for fashion and lifestyle products is by paying with crypto. Access exclusive gift card collections from renowned brands and enjoy the convenience. Redefine your shopping journey and upgrade your wardrobe with just a few clicks. Welcome to the future of fashion!                                    </p>
                                    <button className="button" id='fashionLifestyleBtn' onClick={() => handleCardClick('Fashion & Lifestyle')}>Get Now</button>
       
                                </div>
                                
                            </div>
                        </div>

                        <div className="CardCollageCardSixth" >
                            <div className="mobileFills">
                                <div className="SixthSectMainHeadTxt">
                                    <h3 > Mobile Recharge</h3>
                                    <p className="FithSectMainSecTxt">
                                    Stay connected on the go by using the best mobile recharge with crypto. For seamless and convenient connectivity, simply top up your mobile phone with Bitcoin or other cryptocurrencies.                                    </p>
                                    <button className="button" id='mobileRechargeBtn' onClick={() => handleCardClick('Mobile Recharge')}>Try Now</button>
                                </div>

                                <div className="SixthSectMainImg">
                                    <img src={CollageMiddleImgThree}className="CollageImageSixth"/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
    </div>
  )
}

export default NewCardCollageWeb
