import logo from './logo.svg';
import './App.css';
import LandingComponent from './LandingComponent/LandingComponent';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUsComponent from './AboutUsComponent/AboutUsComponent';
import FooterComponent from './FooterComponent/FooterComponent';
import HeaderComponent from './HeaderComponent/HeaderComponent';
import PrivacyPolicyComponent from './PrivacyPolicyComponent/PrivacyPolicyComponent';
import TermsAndCondition from './TermsNdConditionComponent/TermsAndCondition';
import FAQComponent from './FAQComponent/FAQComponent';
import ContactUsComponent from './ContactUsComponent/ContactUsComponent';
import NewLandingComponent from './LandingComponent/NewLandingComponent/NewLandingComponent';
import MobileFAQComponent from './FAQComponent/MobileFAQComponent/MobileFAQComponent';
import MobilePrivacyPolicy from './PrivacyPolicyComponent/MobilePrivacyPolicy/MobilePrivacyPolicy';
import MobileTermsNdCondition from './TermsNdConditionComponent/MobileTermsNdCondition/MobileTermsNdCondition';


function App() {
  return (
    <div className="MainBackContent">
      <BrowserRouter>
        <Routes>
          {/* Route with header and footer */}
          <Route path='/' element={
            <>
              <HeaderComponent/>
              <NewLandingComponent/>
              <FooterComponent/>
            </>
          }/>
          <Route path='/aboutus' element={
            <>
              <HeaderComponent/>
              <AboutUsComponent/>
              <FooterComponent/>
            </>
          }/>
          <Route path='/privacypolicy' element={
            <>
              <HeaderComponent/>
              <PrivacyPolicyComponent/>
              <FooterComponent/>
            </>
          }/>
          <Route path='/termsandconditions' element={
            <>
              <HeaderComponent/>
              <TermsAndCondition/>
              <FooterComponent/>
            </>
          }/>
          <Route path='/FAQ' element={
            <>
              <HeaderComponent/>
              <FAQComponent/>
              <FooterComponent/>
            </>
          }/>
          <Route path='/contactus' element={
            <>
              <HeaderComponent/>
              <ContactUsComponent/>
              <FooterComponent/>
            </>
          }/>
          {/* Route without header and footer */}
          <Route path='/mobileFAQ' element={<MobileFAQComponent/>}/>   
          <Route path='/mobileprivacypolicy' element={<MobilePrivacyPolicy/>}/>   
          <Route path='/mobiletermsandcondition' element={<MobileTermsNdCondition/>}/>   
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
