// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlaystoreContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.playstoreImg {
    width: 100%;
    transition: transform 0.3s ease; /* Smooth transition */
}

.playstoreImg:hover {
    transform: scale(1.1); /* Use transform to scale the image */
}







@media(max-width:767px){
    

.PlaystoreContainer a{
width: 80%;

}

}`, "",{"version":3,"sources":["webpack://./src/LandingComponent/NewLandingComponent/PlayStoreDownload/playstoreDownload.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,+BAA+B,EAAE,sBAAsB;AAC3D;;AAEA;IACI,qBAAqB,EAAE,qCAAqC;AAChE;;;;;;;;AAQA;;;AAGA;AACA,UAAU;;AAEV;;AAEA","sourcesContent":[".PlaystoreContainer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.playstoreImg {\n    width: 100%;\n    transition: transform 0.3s ease; /* Smooth transition */\n}\n\n.playstoreImg:hover {\n    transform: scale(1.1); /* Use transform to scale the image */\n}\n\n\n\n\n\n\n\n@media(max-width:767px){\n    \n\n.PlaystoreContainer a{\nwidth: 80%;\n\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
