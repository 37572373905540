// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainNewLandingComponent {
    margin-top: 40px;
}


.OnboardingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.NewsLettersSection {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 5px;
    background: #efefef;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    border-radius: 20px;
    margin-bottom: 30px;
}


@media(max-width:767px){




    .NewsLettersSection {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 5px;
        background: #efefef;
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        border-radius: 20px;
        margin-bottom: 30px;
    }

    .NewsLettersTitle {
        font-size: 25px;
    }

    .newsLetterText {
        margin-left: 20px;
        margin-right: 20px;
    }

    .newsLetterInput {
        padding-top: 15px;
        margin-bottom: 35px;
        text-align: center;
        width: 85%;
        margin-right: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/LandingComponent/NewLandingComponent/NewLandingComponent.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;;;;;IAKI;QACI,kBAAkB;QAClB,iBAAiB;QACjB,mBAAmB;QACnB,mBAAmB;QACnB,iBAAiB;QACjB,kBAAkB;QAClB,UAAU;QACV,mBAAmB;QACnB,mBAAmB;IACvB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,iBAAiB;QACjB,kBAAkB;IACtB;;IAEA;QACI,iBAAiB;QACjB,mBAAmB;QACnB,kBAAkB;QAClB,UAAU;QACV,kBAAkB;IACtB;AACJ","sourcesContent":[".MainNewLandingComponent {\n    margin-top: 40px;\n}\n\n\n.OnboardingContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n\n.NewsLettersSection {\n    text-align: center;\n    padding-top: 10px;\n    padding-bottom: 5px;\n    background: #efefef;\n    margin-left: auto;\n    margin-right: auto;\n    width: 75%;\n    border-radius: 20px;\n    margin-bottom: 30px;\n}\n\n\n@media(max-width:767px){\n\n\n\n\n    .NewsLettersSection {\n        text-align: center;\n        padding-top: 10px;\n        padding-bottom: 5px;\n        background: #efefef;\n        margin-left: auto;\n        margin-right: auto;\n        width: 95%;\n        border-radius: 20px;\n        margin-bottom: 30px;\n    }\n\n    .NewsLettersTitle {\n        font-size: 25px;\n    }\n\n    .newsLetterText {\n        margin-left: 20px;\n        margin-right: 20px;\n    }\n\n    .newsLetterInput {\n        padding-top: 15px;\n        margin-bottom: 35px;\n        text-align: center;\n        width: 85%;\n        margin-right: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
