import React, { useState } from "react";
import './Newsletters.css';
import { addNewsLetter } from "../../network/evouchrAPI.js";
import { CiGift } from "react-icons/ci";


const Newsletters = () => {
    const [email, setEmail] = useState("");
    const [confirmation, setConfirmation] = useState("");
    const [error, setError] = useState("");
  
    const handleSignup = async () => {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (!isValidEmail) {
        setError("Please enter your correct email first.");
        setConfirmation("");
      } else {
        const emailBody = {
          email: email,
        };
        const response = await addNewsLetter(emailBody);
        if (response.data.message) {
          setConfirmation(
            response.data.message
              ? response.data.message
              : "Thank you for signing up!"
          );
          setEmail("")
          setError("");
        }
      }
    };
    

    const handleChange = (e) => {
        setEmail(e.target.value);
        setError("");
        setConfirmation("");
      };


    return(
        <>
            <div className="NewsLettersTitle">
                Newsletters
            </div>

            <div className="newsLetterSectionContainer">
                    <span className="newsLetterText">
                        Get the latest Online Voucher news delivered into your inbox
                    </span>
                <div className="newsLetterInput">
                        <input
                        type="email"
                        className="emailInput"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleChange}
                        required
                        />
                        <button className="signupContainer" onClick={handleSignup}>
                            Signup
                        </button>

                        {error && <div className="errorMessage">{error}</div>}
                        {confirmation && (
                        <div className="confirmationMessage">✅{confirmation}</div>
                        )}
                </div>
            </div>
        </>
    )
}

export default Newsletters