import React from 'react';
import './NewCardCollageSection.css';
import { CollageImageLeftOne, CollageMiddleImgFive, CollageMiddleImgFour, CollageMiddleImgSix, CollageMiddleImgThree, CollageRightUpTwo } from "../../../Images";

function NewCardCollageMob({ handleCardClick }) {
  return (
    <div className="card-collage-container">



<div className="card-collage-item card-three" >
        <div className="card-image-container">
          <img src={CollageMiddleImgFour} className="card-image" />
        </div>
        <div className="card-text">
          <h3>Virtual Cards</h3>
          <p>Experience the ease of virtual cards for all your online transactions. Purchase virtual cards with Bitcoin, Ethereum, USDT, and more, making every transaction smoother.</p>
          <button className="button" id='virtualCardsBtn' onClick={() => handleCardClick('cash-&-prepaid-card')}>Shop Now</button>
        </div>
      </div>


      
      <div className="card-collage-item card-two" >
        <div className="card-text">
          <h3>Stay Connected On-The-Go</h3>
          <p>eSIMs offer seamless activation and continuous connectivity without the need to swap out your physical SIM card. Buy your eSIMs with cryptocurrency for added convenience and flexibility.</p>
          <button className="button" id='esimsBtn' onClick={() => handleCardClick('esims')}>Explore Now</button>
        </div>
        <div className="card-image-container">
          <img src={CollageMiddleImgFive} className="card-image" />
        </div>
      </div>

      <div className="card-collage-item card-one" >
      <div className="card-text">

        </div>

        <img src={CollageRightUpTwo} className="card-image" />
        <div className="card-text">
        <h3 className="card-title">Travel & Entertainment</h3>

          <p>Create unforgettable memories effortlessly, thanks to the convenience of cryptocurrency transactions. Discover the best crypto entertainment gift cards and let your journeys begin!</p>
          <button className="button" id='travelEntertainmentBtn' onClick={() => handleCardClick('Travel & Entertainment')}>Order Now</button>
        </div>
      </div>



      <div className="card-collage-item card-four">
        <div className="card-text">
          <h3>Mobile Recharge</h3>
          <p>Stay connected on the go by using the best mobile recharge with crypto. For seamless connectivity, simply top up your mobile phone with Bitcoin or other cryptocurrencies.</p>
          <button className="button" id='mobileRechargeBtn'  onClick={() => handleCardClick('Mobile Recharge')}>Try Now</button>
        </div>
        <div className="card-image-container">
          <img src={CollageMiddleImgThree} className="card-image" />
        </div>
      </div>

      <div className="card-collage-item card-five" >
        <div className="card-image-container">
          <img src={CollageMiddleImgSix} className="card-image" />
        </div>
        <div className="card-text">
          <h3>Fashion & Lifestyle</h3>
          <p>A new way to shop for fashion and lifestyle products is by paying with crypto. Access exclusive gift cards from renowned brands and redefine your shopping journey.</p>
          <button className="button" id='fashionLifestyleBtn' onClick={() => handleCardClick('Fashion & Lifestyle')}>Get Now</button>
        </div>
      </div>

      <div className="card-collage-item card-six" >
        <div className="card-text">
          <h3>Game On With Crypto</h3>
          <p>Gain access to popular gaming platforms like Steam, PlayStation Network, and Xbox Live using crypto. Enhance your gaming experience with secure transactions.</p>
          <button className="button" id='gamingBtn' onClick={() => handleCardClick('Gaming')}>Buy More</button>
        </div>
        <div className="card-image-container">
          <img src={CollageImageLeftOne} className="card-image" />
        </div>
      </div>
    </div>
  );
}

export default NewCardCollageMob;
